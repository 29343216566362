module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Radwege-Check","short_name":"Radwege-Check","start_url":"/","background_color":"white","theme_color":"#fff8e8","display":"minimal-ui","icon":"src/components/assets/radwegecheck-logo-bildmarke-mehrfarbig.svg","legacy":false,"lang":"de-DE","theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8341c8738e62b7f288dadaaef3ba9afc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"6","matomoUrl":"https://s.fixmycity.de","siteUrl":"https://radwege-check.de","requireConsent":false,"requireCookieConsent":false,"disableCookies":true,"respectDnt":false,"dev":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
