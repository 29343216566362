exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-auswertung-tsx": () => import("./../../../src/pages/auswertung.tsx" /* webpackChunkName: "component---src-pages-auswertung-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-evaluacion-tsx": () => import("./../../../src/pages/evaluacion.tsx" /* webpackChunkName: "component---src-pages-evaluacion-tsx" */),
  "component---src-pages-hauptstrassen-alle-tsx": () => import("./../../../src/pages/hauptstrassen/alle.tsx" /* webpackChunkName: "component---src-pages-hauptstrassen-alle-tsx" */),
  "component---src-pages-hauptstrassen-export-tsx": () => import("./../../../src/pages/hauptstrassen/export.tsx" /* webpackChunkName: "component---src-pages-hauptstrassen-export-tsx" */),
  "component---src-pages-hauptstrassen-index-tsx": () => import("./../../../src/pages/hauptstrassen/index.tsx" /* webpackChunkName: "component---src-pages-hauptstrassen-index-tsx" */),
  "component---src-pages-hauptstrassen-scenes-primary-csv-scene-id-tsx": () => import("./../../../src/pages/hauptstrassen/{scenesPrimaryCsv.sceneId}.tsx" /* webpackChunkName: "component---src-pages-hauptstrassen-scenes-primary-csv-scene-id-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-nebenstrassen-alle-tsx": () => import("./../../../src/pages/nebenstrassen/alle.tsx" /* webpackChunkName: "component---src-pages-nebenstrassen-alle-tsx" */),
  "component---src-pages-nebenstrassen-export-tsx": () => import("./../../../src/pages/nebenstrassen/export.tsx" /* webpackChunkName: "component---src-pages-nebenstrassen-export-tsx" */),
  "component---src-pages-nebenstrassen-index-tsx": () => import("./../../../src/pages/nebenstrassen/index.tsx" /* webpackChunkName: "component---src-pages-nebenstrassen-index-tsx" */),
  "component---src-pages-nebenstrassen-scenes-secondary-csv-scene-id-tsx": () => import("./../../../src/pages/nebenstrassen/{scenesSecondaryCsv.sceneId}.tsx" /* webpackChunkName: "component---src-pages-nebenstrassen-scenes-secondary-csv-scene-id-tsx" */),
  "component---src-pages-open-data-tsx": () => import("./../../../src/pages/open-data.tsx" /* webpackChunkName: "component---src-pages-open-data-tsx" */),
  "component---src-pages-presse-tsx": () => import("./../../../src/pages/presse.tsx" /* webpackChunkName: "component---src-pages-presse-tsx" */),
  "component---src-pages-report-tsx": () => import("./../../../src/pages/report.tsx" /* webpackChunkName: "component---src-pages-report-tsx" */),
  "component---src-pages-vergleichen-index-tsx": () => import("./../../../src/pages/vergleichen/index.tsx" /* webpackChunkName: "component---src-pages-vergleichen-index-tsx" */)
}

